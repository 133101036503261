@use '@angular/material' as mat;

@include mat.core();

.gj {
  $quran-fore-color: rgba(0,0,0,.83);

  $primary-palette: mat.define-palette(mat.$grey-palette);
  $accent-palette: mat.define-palette(mat.$grey-palette);
  $warn-palette: mat.define-palette(mat.$red-palette);

  $light-theme: mat.define-light-theme((
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette,
    )
  ));

  @include mat.core-theme($light-theme);
  // @include mat.ripple-theme($light-theme);
  // @include mat.focus-indicators-theme($light-theme);
  @include mat.button-theme($light-theme);
  @include mat.button-toggle-theme($light-theme);
  @include mat.icon-theme($light-theme);
  @include mat.input-theme($light-theme);
  @include mat.sidenav-theme($light-theme);
  @include mat.slide-toggle-theme($light-theme);
  @include mat.slider-theme($light-theme);
  @include mat.toolbar-theme($light-theme);
  @include mat.tooltip-theme($light-theme);

  .mat-toolbar,
  .mat-drawer,
  .jf {
    background-color: white;
    color: $quran-fore-color;
  }

  .jf {
    input {
      color: $quran-fore-color;
    }
  }

  .mat-drawer-container {
    background-color: #fcfcfc;
    color: $quran-fore-color;
  }

  .mat-progress-bar-buffer {
    background-color: #ccc;
  }

  .mat-button-toggle-appearance-standard {
    background-color: white;
  }

  .mat-button-toggle-checked {
    background-color: rgba(0,0,0,.178);
  }

  .jh {
    &:hover {
      background-color: #eaeaea;

      .xg {
        // background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36' preserveAspectRatio='xMidYMid meet'><circle r='16' cx='18' cy='18' stroke='#cccccc' stroke-width='3' fill='#000000'></circle></svg>");
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAzNiAzNicgcHJlc2VydmVBc3BlY3RSYXRpbz0neE1pZFlNaWQgbWVldCc+PGNpcmNsZSByPScxNicgY3g9JzE4JyBjeT0nMTgnIHN0cm9rZT0nI2NjY2NjYycgc3Ryb2tlLXdpZHRoPSczJyBmaWxsPScjMDAwMDAwJz48L2NpcmNsZT48L3N2Zz4=");
        color: white;
      }
    }
  }

  .zc {
    .mc {
      &:hover {
        background-color: #eaeaea;
      }
    }
  }

  .zz {
    &._1 {
      &:hover {
        background-color: #eaeaea;
        cursor: pointer;

        .st {
          background-color: black;
          color: white;
        }
      }
    }

    &._2 {
      .ia {
        .st {
          &:hover {
            background-color: black;
            color: white;
            cursor: pointer;
          }
        }
      }
    }

    .ia {
      &.dd {
        .cr {
          .ig {
            stroke: black;
          }
        }
      }
    }
  }

  .br,
  &.mm {
    .yr,
    &.yr {
      box-shadow: inset 0 -3px 0 black;
      text-shadow: 0 0 1px black;
    }
  }

  .ct {
    .yr {
      box-shadow: inset 0 -2px 0 black;
      text-shadow: 0 0 1px black;
      padding-bottom: 3px;
    }
  }

  // .tw {
  //   .mat-fab {
  //     background-color: white !important;
  //   }
  // }

  .wn {
    border: 1px dotted #ccc;
  }

  .cr {
    .bz {
      stroke: #ccc;
    }
  }

  .xg {
    // background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36' preserveAspectRatio='xMidYMid meet'><circle r='16' cx='18' cy='18' stroke='#cccccc' stroke-width='3' fill='none'></circle></svg>");
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAzNiAzNicgcHJlc2VydmVBc3BlY3RSYXRpbz0neE1pZFlNaWQgbWVldCc+PGNpcmNsZSByPScxNicgY3g9JzE4JyBjeT0nMTgnIHN0cm9rZT0nI2NjY2NjYycgc3Ryb2tlLXdpZHRoPSczJyBmaWxsPSdub25lJz48L2NpcmNsZT48L3N2Zz4=");

    &:hover {
      // background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36' preserveAspectRatio='xMidYMid meet'><circle r='16' cx='18' cy='18' stroke='#cccccc' stroke-width='3' fill='#000000'></circle></svg>");
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAzNiAzNicgcHJlc2VydmVBc3BlY3RSYXRpbz0neE1pZFlNaWQgbWVldCc+PGNpcmNsZSByPScxNicgY3g9JzE4JyBjeT0nMTgnIHN0cm9rZT0nJTIzY2NjY2NjJyBzdHJva2Utd2lkdGg9JzMnIGZpbGw9JyUyMzAwMDAwMCc+PC9jaXJjbGU+PC9zdmc+");
      color: white;
    }
  }

  .wx .vr {
    // background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><g><path style='fill:#000000' d='M255.07,511.49A255.5,255.5,0,0,1,75.33,75.33,255.51,255.51,0,0,1,453.18,418.49H334.79A181.42,181.42,0,0,0,436.55,256c0-99.56-81-180.55-180.55-180.55S75.45,156.44,75.45,256c0,99.16,80.53,180.05,179.62,180.55Z'/></g><g><path style='fill:#000000' d='M424.45,498.24l5.9-14.9h17l-8.07-22.6,10.1-25.54L478.31,512H457L452,498.24Z'/><path style='fill:#de2c2c' d='M406.26,512l31-76.8H416.69l-21.18,49.63L380.45,435.2H364.67L348.5,484.83l-11.41-22.62L326.77,494l10.48,18h20.2l14.62-44.51L386,512Z'/><path style='fill:#000000' d='M293.82,485.64h12.64a37.06,37.06,0,0,0,10.23-1.29L320,474.28l9.14-28.16a22.27,22.27,0,0,0-2.38-3.13q-7.05-7.78-20.6-7.79H274.33V512h19.49Zm16.73-32.77a10,10,0,0,1,2.75,7.4,10.78,10.78,0,0,1-2.42,7.42q-2.64,3-9.76,3h-7.3V450.1h7.36Q307.79,450.1,310.55,452.87Z'/></g></svg>");
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJz48Zz48cGF0aCBzdHlsZT0nZmlsbDojMDAwMDAwJyBkPSdNMjU1LjA3LDUxMS40OUEyNTUuNSwyNTUuNSwwLDAsMSw3NS4zMyw3NS4zMywyNTUuNTEsMjU1LjUxLDAsMCwxLDQ1My4xOCw0MTguNDlIMzM0Ljc5QTE4MS40MiwxODEuNDIsMCwwLDAsNDM2LjU1LDI1NmMwLTk5LjU2LTgxLTE4MC41NS0xODAuNTUtMTgwLjU1Uzc1LjQ1LDE1Ni40NCw3NS40NSwyNTZjMCw5OS4xNiw4MC41MywxODAuMDUsMTc5LjYyLDE4MC41NVonLz48L2c+PGc+PHBhdGggc3R5bGU9J2ZpbGw6IzAwMDAwMCcgZD0nTTQyNC40NSw0OTguMjRsNS45LTE0LjloMTdsLTguMDctMjIuNiwxMC4xLTI1LjU0TDQ3OC4zMSw1MTJINDU3TDQ1Miw0OTguMjRaJy8+PHBhdGggc3R5bGU9J2ZpbGw6I2RlMmMyYycgZD0nTTQwNi4yNiw1MTJsMzEtNzYuOEg0MTYuNjlsLTIxLjE4LDQ5LjYzTDM4MC40NSw0MzUuMkgzNjQuNjdMMzQ4LjUsNDg0LjgzbC0xMS40MS0yMi42MkwzMjYuNzcsNDk0bDEwLjQ4LDE4aDIwLjJsMTQuNjItNDQuNTFMMzg2LDUxMlonLz48cGF0aCBzdHlsZT0nZmlsbDojMDAwMDAwJyBkPSdNMjkzLjgyLDQ4NS42NGgxMi42NGEzNy4wNiwzNy4wNiwwLDAsMCwxMC4yMy0xLjI5TDMyMCw0NzQuMjhsOS4xNC0yOC4xNmEyMi4yNywyMi4yNywwLDAsMC0yLjM4LTMuMTNxLTcuMDUtNy43OC0yMC42LTcuNzlIMjc0LjMzVjUxMmgxOS40OVptMTYuNzMtMzIuNzdhMTAsMTAsMCwwLDEsMi43NSw3LjQsMTAuNzgsMTAuNzgsMCwwLDEtMi40Miw3LjQycS0yLjY0LDMtOS43NiwzaC03LjNWNDUwLjFoNy4zNlEzMDcuNzksNDUwLjEsMzEwLjU1LDQ1Mi44N1onLz48L2c+PC9zdmc+");
  }
}